import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { adaptDirectSelectionSources, adaptSpecializations } from "@local/utils/adapters";
import { ROLES } from "@local/utils/constants";

// POST_REFACTORING_TODO: These functions are too heavy, check if they can be memoized, default agency should be the current one

export const getOpenProjectFilters = (role, countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses) => {
  let result = [];
  result.push({
    id: "title",
    name: "title",
    label: formatMessage({ id: "pages.projects.list.utils.filters.search" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.search",
    }),
    type: "text",
  });
  result.push({
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.projects.list.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  });
  result.push({
    id: "locations",
    name: "locations",
    label: formatMessage({ id: "pages.projects.list.utils.filters.location" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
    multiple: true,
  });
  result.push({
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.projects.list.utils.filters.sector" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  });
  if (role === ROLES.AGENCY) {
    result.push({
      id: "displayID",
      name: "displayID",
      label: formatMessage({ id: "pages.projects.list.utils.filters.cfeiID" }),
      placeholder: formatMessage({
        id: "pages.projects.list.utils.filters.cfeiID",
      }),
      type: "text",
    });
  }
  result.push({
    id: "agency",
    name: "agency",
    label: formatMessage({ id: "pages.projects.list.utils.filters.agency" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  });
  if (role === ROLES.AGENCY) {
    result.push({
      id: "posted_date",
      fromName: "posted_from_date",
      toName: "posted_to_date",
      label: formatMessage({ id: "pages.projects.list.utils.filters.date" }),
      placeholder: formatMessage({
        id: "pages.projects.list.utils.filters.date",
      }),
      type: "dateRange",
    });
    result.push({
      id: "focal_points",
      name: "focal_points",
      label: formatMessage({
        id: "pages.projects.list.utils.filters.focalPoint",
      }),
      placeholder: formatMessage({
        id: "pages.projects.list.utils.filters.select",
      }),
      type: "select",
      valueField: "id",
      labelField: "label",
      multiple: true,
      options: focalPoints,
    });
    result.push({
      id: "status",
      name: "status",
      type: "radio",
      options: statuses,
    });
  }
  return result;
};

export const getDirectProjectFilters = (countries, getLocations, locations, setLocations, sectors, agencies, focalPoints, statuses, directSelectionSources) => [
  {
    id: "title",
    name: "title",
    label: formatMessage({ id: "pages.projects.list.utils.filters.search" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.search",
    }),
    type: "text",
  },
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.projects.list.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  },
  {
    id: "locations",
    name: "locations",
    label: formatMessage({ id: "pages.projects.list.utils.filters.location" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
    multiple: true,
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.projects.list.utils.filters.sector" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "displayID",
    name: "displayID",
    label: formatMessage({ id: "pages.projects.list.utils.filters.DSRID" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.DSRID",
    }),
    type: "text",
  },
  {
    id: "selected_source",
    name: "selected_source",
    label: formatMessage({ id: "pages.projects.list.utils.filters.direct" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.chooseOne",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    options: adaptDirectSelectionSources(directSelectionSources),
  },
  {
    id: "agency",
    name: "agency",
    label: formatMessage({ id: "pages.projects.list.utils.filters.agency" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: agencies,
  },
  {
    id: "focal_points",
    name: "focal_points",
    label: formatMessage({
      id: "pages.projects.list.utils.filters.focalPointOne",
    }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.focalPointOne",
    }),
    type: "select",
    valueField: "id",
    labelField: "label",
    multiple: true,
    options: focalPoints,
  },
  {
    id: "dsr_focal_point",
    name: "dsr_focal_point",
    label: formatMessage({
      id: "pages.projects.list.utils.filters.dsrFocalPoint",
    }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.dsrFocalPoint",
    }),
    type: "select",
    valueField: "id",
    labelField: "label",
    options: focalPoints,
  },
  {
    id: "status",
    name: "status",
    type: "radio",
    options: statuses,
  },
  {
    id: "posted_date",
    fromName: "posted_from_date",
    toName: "posted_to_date",
    label: formatMessage({ id: "pages.projects.list.utils.filters.date" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.date",
    }),
    type: "dateRange",
  },
];

export const getUnsolicitedProjectFilters = (countries, getLocations, locations, setLocations, sectors, statuses) => [
  {
    id: "title",
    name: "title",
    label: formatMessage({ id: "pages.projects.list.utils.filters.search" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.search",
    }),
    type: "text",
  },
  {
    id: "country_code",
    name: "country_code",
    label: formatMessage({ id: "pages.projects.list.utils.filters.country" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.country",
    }),
    type: "select",
    valueField: "value",
    labelField: "label",
    onChangeExtra: (event) => {
      const country_code = event.target.value;
      if (country_code) {
        getLocations({ country_code })
          .then((result) => setLocations(result?.data?.results ?? []))
          .catch(() => setLocations([]));
      }
      setLocations([]);
    },
    clearOnChange: ["locations"],
    options: countries,
  },
  {
    id: "locations",
    name: "locations",
    label: formatMessage({ id: "pages.projects.list.utils.filters.location" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.location",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: locations,
    multiple: true,
  },
  {
    id: "specializations",
    name: "specializations",
    label: formatMessage({ id: "pages.projects.list.utils.filters.sector" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.choose",
    }),
    type: "select",
    valueField: "id",
    labelField: "name",
    options: adaptSpecializations(sectors),
    multiple: true,
  },
  {
    id: "ds_converted",
    name: "ds_converted",
    label: formatMessage({ id: "pages.projects.list.utils.filters.show" }),
    placeholder: formatMessage({
      id: "pages.projects.list.utils.filters.show",
    }),
    type: "checkbox",
  },
];
