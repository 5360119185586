/* eslint-disable max-len */

import list from "./list";
import update from "./update";
import create from "./create";
import details, { reset as detailsReset } from "./details";
import destroy from "./destroy";

export const shellProfilesList = list("SHELL_PROFILES_LIST", "/partners/shell-profiles/");
export const shellProfileCurrent = details("SHELL_PROFILE_CURRENT", "/partners/current-shell-profile/");
export const partnerVendorNumberRequestDetails = details("PARTNER_VENDOR_REQUEST_DETAILS", "/externals/vendor-number-request-by-partner-id/:partnerId/", false);
export const unDataDetails = details("UN_DATA_DETAILS", "/externals/un-data/:agencyName/:partnerId/", false);
export const unDocumentsList = list("UN_DOCUMENTS_LIST", "/externals/partner-un-documents/:partnerId/");
export const createPartnerUnDocument = create("UN_DOCUMENT_CREATE", "/externals/partner-un-documents/:partnerId/");
export const getPartnerUnDocumentItem = details("UN_DOCUMENT_DETAILS", "/externals/partner-un-document-details/:id/");
export const getPartnerUnDocumentItemReset = detailsReset("UN_DOCUMENT_DETAILS");
export const updatePartnerUnDocument = update("UN_DOCUMENT_UPDATE", "/externals/partner-un-document-details/:id/");
export const destroyPartnerUnDocument = destroy("UN_DOCUMENT_DELETE", "/externals/partner-un-document-details/:id/", false);
export const getPartnerVendorNumberLinkItem = details("VENDOR_NUMBER_LINK_DETAILS", "/externals/partner-vendor-number-link/:partnerId/", false);
export const updatePartnerVendorNumberLink = create("VENDOR_NUMBER_LINK_UPDATE", "/externals/partner-vendor-number-link/:partnerId/", false);
export const destroyPartnerVendorNumberLink = destroy("VENDOR_NUMBER_LINK_DELETE", "/externals/partner-vendor-number-link/:partnerId/", false);
export const specialPartnerDetails = details("SPECIAL_PARTNER_DETAILS", "/partners/:partnerId/summary/", false);
export const specialPartnerDetailsOnEdit = details("SPECIAL_PARTNER_ON_EDIT", "/partners/:partnerId/summary/", false);
export const getSpecialPartnerReset = detailsReset("SPECIAL_PARTNER_DETAILS");
export const getSpecialPartnerOnEditReset = detailsReset("SPECIAL_PARTNER_ON_EDIT");
export const specialPartners = details("SPECIAL_PARTNERS", "/partners/special-partner/", false);
export const loadPartnerNames = details("HQ_PARTNERS_NAMES", "/partners/basic/", false);
export const getApplicationsToScoreList = list("APPLICATIONS_TO_SCORE_LIST", "/dashboard/applications-to-score/");
export const getOpenCfeisList = list("OPEN_CFEIS_LIST", "/dashboard/open-projects/");
export const getPartnerDecisionsList = list("PARTNER_DECISIONS_LIST", "/dashboard/applications-decisions/");
export const loadPartners = list("PARTNER_NAMES", "/partners/basic/");
export const getOpenProjectsList = list("OPEN_PROJECTS_LIST", "/projects/open/");
export const getPinnedProjectsList = list("PINNED_PROJECTS_LIST", "/projects/pins/");
export const getDirectProjectsList = list("DIRECT_PROJECTS_LIST", "/projects/direct/");
export const getUnsolicitedProjectsList = list("UNSOLICITED_PROJECTS_LIST", "/projects/unsolicited/");
export const getCountriesList = list("COUNTRIES_LIST", "/config/countries/", false, "countriesList");
export const getLocationsList = list("LOCATIONS_LIST", "/common/admin-levels/");
export const getFocalPointsList = list("FOCAL_POINTS_LIST", "/agencies/:officeId/members/");
export const getAgenciesList = list("AGENCIES_LIST", "/agencies/", false, "agenciesList");
export const getSectorsList = list("SECTORS_LIST", "/config/sectors/", false, "sectorsList");
export const getAgencyOfficesList = list("AGENCY_OFFICES_LIST", "/agencies/:agencyId/");
export const getAgencyAdminsList = list("AGENCY_ADMINS_LIST", "/agencies/:officeId/admins/");
export const getPartnersList = list("PARTNERS_LIST", "/partners/");
export const createFirstAccessPartner = create("PARTNER_FIRST_ACCESS_CREATE", "/notifications/first-access/partner/");
export const createFirstAccessAgency = create("AGENCY_FIRST_ACCESS_CREATE", "/notifications/first-access/agency/");
export const getPartnerProfileVerificationsList = list("PARTNER_PROFILE_VERIFICATIONS_LIST", "/partners/:id/verifications/");
export const getPartnerSummaryDetails = details("PARTNER_SUMMARY_DETAILS", "/partners/:id/summary/", false);
export const getPartnerProfileDataDetails = details("PARTNER_PROFILE_DATA_DETAILS", "/partners/:id/", false);
export const createPartnerVendorNumberRequest = create("PARTNER_VENDOR_REQUEST_CREATE", "/externals/vendor-number-request/");
export const deletePartnerVendorNumberRequest = destroy("PARTNER_VENDOR_REQUEST_DESTROY", "/externals/vendor-number-request/:id/");
export const createPartnerVendorNumber = create("PARTNER_VENDOR_NUMBER_CREATE", "/externals/vendor-number/partner/");
export const deletePartnerVendorNumber = destroy("PARTNER_VENDOR_NUMBER_DELETE", "/externals/vendor-number/partner/:vendorId/");
export const getRiskProfileDetails = details("RISK_PROFILE_DETAILS", "/risk-profile/:id/", false);
export const getAssessmentReportsList = list("ASSESSMENT_REPORTS", "/risk-profile/assessment-reports/");
export const getAuditReportsList = list("AUDIT_REPORTS", "/risk-profile/audit-reports/");
export const getProgressReportsList = list("PROGRESS_REPORTS", "/risk-profile/progress-reports/");
export const getUNDataList = list("UN_DATA_LIST", "/externals/un-data/:id/");
export const getObservationsList = list("RISK_OBSERVATIONS", "/risk-profile/observations/");
export const createObservation = create("RISK_OBSERVATION_CREATE", "/risk-profile/observations/");
export const updateObservation = update("RISK_OBSERVATION_UPDATE", "/risk-profile/observation/:id/");
export const deleteObservation = destroy("RISK_OBSERVATION_DESTROY", "/risk-profile/observation/:id/");
export const getSanctionMatch = details("SANCTION_MATCH_DETAILS", "/partners/:id/sanction/");
export const updateSanctionMatch = update("SANCTION_MATCH_UPDATE", "/partners/:id/sanction/");
export const getFlagsList = list("RISK_FLAGS", "/risk-profile/flags/");
export const createFlag = create("RISK_FLAG_CREATE", "/risk-profile/flags/");
export const updateFlag = update("RISK_FLAG_UPDATE", "/risk-profile/flag/:id/");
export const deactivateFlag = destroy("RISK_FLAG_DEACTIVATE", "/risk-profile/flag/:id/");
export const createComment = create("RISK_FLAG_COMMENT_CREATE", "/common/comments/");
export const getPartnerVerificationsFullList = list("PARTNER_VERIFICATIONS_FULL_LIST", "/partners/:id/verifications/");
export const getPartnerFlagsList = list("PARTNER_FLAGS_LIST", "/partners/:id/flags/");
export const getPartnerApplicationsList = list("PARTNER_APPLICATIONS_LIST", "/projects/applications/");
export const updateUserProfile = update("USER_PROFILE_UPDATE", "/accounts/me/profile/");
export const getPartnerMembersList = list("PARTNER_MEMBERS_LIST", "/partners/:id/members/");
export const getPartnerReports = list("PARTNER_REPORTS_LIST", "/reports/partners/");
export const getPartnerProfileReports = list("EXPORT_PARTNER_PROFILE_REPORTS", "/reports/partners/profile/export/xlsx/");
export const getPartnerContactReports = list("EXPORT_PARTNER_CONTACT_REPORTS", "/reports/partners/contact/export/xlsx/");
export const getPartnerMappingReports = list("EXPORT_PARTNER_MAPPING_REPORTS", "/reports/partners/mapping/export/xlsx/");
export const getProjectReports = list("PROJECT_REPORTS_LIST", "/reports/projects/");
export const getProjectDetailsReports = list("EXPORT_PROJECTS_DETAILS_REPORTS", "/reports/projects/details/export/xlsx/");
export const getVerificationsReports = list("VERIFICATION_REPORTS_LIST", "/reports/verifications-observations/");
export const getPseaReports = list("PSEA_REPORTS_LIST", "/reports/psea-reports/");
export const getPseaDetailsReports = list("EXPORT_PSEA_REPORTS", "/reports/psea-reports/export/xlsx/");
export const getVerificationsDetailsReports = list("EXPORT_VERIFICATION_REPORTS", "/reports/verifications-observations/export/xlsx/");
export const getPartnersToInviteList = list("PARTNERS_TO_INVITE_LIST", "/partners/basic/");
export const invitePartners = update("INVITE_PARTNERS_UPDATE", "/projects/:projectId/");
export const updateReviewers = update("PROJECT_REVIEWERS_UPDATE", "/projects/:projectId/");
export const createOpenProject = create("OPEN_PROJECT_CREATE", "/projects/open/");
export const updateOpenProject = update("OPEN_PROJECT_UPDATE", "/projects/:id/");
export const deleteProject = destroy("PROJECT_DELETE", "/projects/:id/");
export const cancelProject = destroy("PROJECT_CANCEL_DELETE", "/projects/:id/");
export const getProjectDetails = details("PROJECT_DETAILS", "/projects/:id/", false);
export const getProjectApplicationDetails = details("PROJECT_APPLICATION_DETAILS", "/projects/application/:id/", false);
export const updateProjectApplication = update("PROJECT_APPLICATION_UPDATE", "/projects/application/:id/");
export const getPartnerProjectApplication = details("PARTNER_PROJECT_APPLICATION_DETAILS", "/projects/:projectId/partner-application/");
export const createDirectProject = create("OPEN_DIRECT_CREATE", "/projects/direct/");
export const updateDirectProject = update("OPEN_DIRECT_UPDATE", "/projects/:id/");
export const completeAssessment = create("COMPLETE_ASSESSMENT_CREATE", "/projects/:id/applications/complete-assessments/");
export const getOpenProjectRequestsList = list("OPEN_PROJECT_REQUESTS_LIST", "/projects/:id/questions/");
export const getOpenApplicationsList = list("OPEN_APPLICATIONS_LIST", "/projects/applications/open/");
export const getDirectApplicationsList = list("DIRECT_APPLICATIONS_LIST", "/projects/applications/direct/");
export const getUnsolicitedApplicationsList = list("UNSOLICITED_APPLICATIONS_LIST", "/projects/applications/unsolicited/");
export const createUnsolicitedApplication = create("UNSOLICITED_APPLICATION_CREATE", "/projects/applications/unsolicited/");
export const updateUnsolicitedApplication = update("UNSOLICITED_APPLICATION_UPDATE", "/projects/applications/unsolicited/:id/manage/");
export const postUCN = create("UNSOLICITED_UCN_CREATE", "/projects/applications/unsolicited/:id/manage/");
export const deleteUCN = destroy("UNSOLICITED_UCN_DELETE", "/projects/applications/unsolicited/:id/manage/");
export const updateProjectDetails = update("PROJECT_DETAILS_UPDATE", "/projects/:id/");
export const sendProjectRequest = create("SEND_PROJECT_REQUEST_CREATE", "/projects/:id/send-to-publish/");
export const publishProjectRequest = create("PUBLISH_PROJECT_REQUEST_CREATE", "/projects/:id/publish/");
export const getApplicationReviews = list("APPLICATION_REVIEWS_LIST", "/projects/applications/:applicationId/reviewers-status/");
export const createApplicationReview = create("APPLICATION_REVIEW_CREATE", "/projects/applications/:applicationId/reviewer-assessments/:reviewerId/");
export const updateApplicationReview = update("APPLICATION_REVIEW_UPDATE", "/projects/applications/:applicationId/reviewer-assessments/:reviewerId/");
export const getApplicationComparison = list("APPLICATION_COMPARISON_LIST", "/projects/:id/applications/compare-selected/");
export const getPartnerProfilesList = details("PARTNER_PROFILES_LIST", "/partners/:partnerId/org-profile/");
export const updatePartnerIdentification = update("PARTNER_IDENTIFICATION_UPDATE", "/partners/:id/identification/");
export const updatePartnerMailing = update("PARTNER_MAILING_UPDATE", "/partners/:id/contact-information/");
export const updatePartnerMandateMission = update("PARTNER_MANDATE_MISSION_UPDATE", "/partners/:id/mandate-mission/");
export const updatePartnerFund = update("PARTNER_FUND_UPDATE", "/partners/:id/funding/");
export const updatePartnerCollaboration = update("PARTNER_COLLABORATION_UPDATE", "/partners/:id/collaboration/");
export const updatePartnerProjectImplementation = update("PARTNER_PROJECT_IMPLEMENTATION_UPDATE", "/partners/:id/project-implementation/");
export const updatePartnerOtherInformation = update("PARTNER_OTHER_INFORMATION_UPDATE", "/partners/:id/other-info/");
export const getNotificationsList = list("NOTIFICATIONS_LIST", "/notifications/notifications-list/");
export const updateNotifications = update("NOTIFICATIONS_UPDATE", "/notifications/notifications-list/");
export const getNotificationsNotReadList = list("NOTIFICATIONS_NOT_READ_LIST", "/notifications/notifications-list/");
export const createPartnerVerification = create("PARTNER_VERIFICATION_CREATE", "/partners/:id/verifications/");
export const getPartnerVerificationsList = list("PARTNER_VERIFICATIONS_LIST", "/partners/:id/verifications/");
export const getClarificationRequestsList = list("CLARIFICATION_REQUESTS_LIST", "/projects/:projectId/questions/");
export const getClarificationAnswersList = list("CLARIFICATION_ANSWERS_LIST", "/projects/:projectId/answers/");
export const addClarificationRequest = create("CLARIFICATION_REQUESTS_CREATE", "/projects/:projectId/questions/");
export const addClarificationAnswer = create("CLARIFICATION_ANSWERS_CREATE", "/projects/:projectId/answers/");
export const removeClarificationAnswer = destroy("CLARIFICATION_ANSWERS_DELETE", "/projects/answers/:id/");
export const getReviewersList = list("REVIEWERS_LIST", "/projects/:id/applications/reviewers/");
export const sendReminder = create("REMINDER_CREATE", "/projects/:id/applications/reviewers/:reviewerId/notify/");
export const getReviewSummary = details("REVIEW_SUMMARY_DETAILS", "/projects/:id/review-summary/");
export const updateReviewSummary = update("REVIEW_SUMMARY_UPDATE", "/projects/:id/review-summary/");
export const createPartnerFlag = create("PARTNER_FLAG_CREATE", "/partners/:id/flags/");
export const updatePartnerFlag = update("PARTNER_FLAG_UPDATE", "/partners/:id/flags/:flagId/");
export const createShellProfile = create("SHELL_PROFILE_CREATE", "/partners/shell-profiles/");
export const getShellProfilesList = list("SHELL_PROFILE_LIST", "/partners/shell-profiles/");
export const createShellProfileNotification = create("SHELL_PROFILE_NOTIFICATION_CREATE", "/partners/notify-shell-profile/");
export const getPartnerHqsList = list("PARTNER_HQS_LIST", "/partners/basic/", false);
export const getPartnersBasicInformationList = list("PARTNER_BASIC_INFORMATION_LIST", "/partners/basic/");
export const createPartnerRegistration = create("PARTNER_REGISTRATION_CREATE", "/accounts/registration");
export const createSpecialPartner = create("SPECIAL_PARTNERS_CREATE", "/partners/special-partner/", false);
export const updateSpecialPartner = update("SPECIAL_PARTNERS_UPDATE", "/partners/:id/special-partner/", false);
export const deleteSpecialPartner = destroy("SPECIAL_PARTNERS_DELETE", "/partners/:id/special-partner/");
export const getSpecialPartnerSubNationalsList = list("SPECIAL_PARTNER_SUB_NATIONALS_LIST", "/partners/:id/special-partner-subnationals/", false);
export const destroyInvitedPartner = destroy("INVITED_PARTNERS_DELETE", "/partners/shell-profile/:id");
export const uploadConceptNote = create("CONCEPT_NOTE_CREATE", "/projects/:id/partner-applications/");
export const togglePin = update("TOGGLE_PIN_UPDATE", "/projects/pins/");
export const deleteUploadedConceptNote = destroy("CONCEPT_NOTE_DELETE", "/projects/:id/partner-applications-delete/");
export const getSubscriptionsList = list("SUBSCRIPTIONS_LIST", "/rss/subscriptions/:id/");
export const createSubscription = create("SUBSCRIPTION_CREATE", "/rss/subscriptions/");
export const updateSubscription = update("SUBSCRIPTION_UPDATE", "/rss/subscriptions/:id/");
export const deleteSubscription = destroy("SUBSCRIPTION_DESTROY", "/rss/subscriptions/:id/");
export const getTagsList = list("TAGS_LIST", "/rss/tags/");
export const getRssChannelList = list("RSS_CHANNEL_LIST", "/rss/channels/");
export const getUsersList = list("USERS_LIST", "/manage/user/");
export const getPartnerUsersList = list("ORG_USERS_LIST", "/manage/partner-users/");
export const createUser = create("USER_CREATE", "/manage/user/");
export const updateUser = update("USER_UPDATE", "/manage/user/:id/");
export const toggleUser = update("USER_TOGGLE", "/manage/user/:id/");
export const getOfficesList = list("OFFICES_LIST", "/manage/offices/");
export const getRegionalOfficesList = list("REGIONAL_OFFICES_LIST", "/agencies/regional-office/");
export const createRegionalOffice = create("REGIONAL_OFFICE_CREATE", "/agencies/regional-office/");
export const getRegionalOffice = details("REGIONAL_OFFICE_DETAILS", "/agencies/regional-office/:id/");
export const updateRegionalOffice = update("REGIONAL_OFFICE_UPDATE", "/agencies/regional-office/:id/");
export const deleteRegionalOffice = destroy("REGIONAL_OFFICE_DESTROY", "/agencies/regional-office/:id/");
export const createRegionalOfficeMember = create("REGIONAL_OFFICE_MEMBER_CREATE", "/agencies/regional-office-members/");
export const getRegionalOfficeMember = list("REGIONAL_OFFICE_MEMBER", "/agencies/regional-office-members/:id/");
export const deleteRegionalOfficeMember = destroy("REGIONAL_OFFICE_MEMBER_DESTROY", "/agencies/regional-office-members/:id/");
export const createCountryProfile = create("COUNTRY_PROFILE_CREATE", "/partners/:partnerId/country-profile/");
export const getApplicationFeedbackList = list("APPLICATION_FEEDBACK_LIST", "/projects/application/:applicationId/feedback/");
export const postApplicationFeedback = create("APPLICATION_FEEDBACK_CREATE", "/projects/application/:applicationId/feedback/");
export const convertCnToDirectSelection = create("CONVERT_TO_DIRECT_SELECTION_CREATE", "/projects/application/:applicationId/convert-unsolicited/");
export const getPartnerUnData = list("PARTNER_UN_DATA_LIST", "/externals/partner-details/:agencyId/:partnerId/");
export const getAgencyReviewersList = list("AGENCY_REVIEWERS_LIST", "/agencies/:officeId/members/");
export const getAgencyFocalPointsList = list("AGENCY_FOCAL_POINTS", "/agencies/:id/members/");
export const getAgencyMemberReports = list("AGENCY_MEMBER_REPORTS_LIST", "/reports/agency-members/export/xlsx/");
export const isUserMigrated = details("IS_USER_MIGRATED", "/auth/is_migrated/");
export const isUserInvited = details("IS_USER_INVITED", "/accounts/is-invited/");
export const getOpenProjectApplicationsAllList = list("OPEN_PROJECT_APPLICATIONS_ALL_LIST", "/projects/:projectId/applications/");
export const getOpenProjectApplicationsPreselectedList = list("OPEN_PROJECT_APPLICATIONS_PRESELECTED_LIST", "/projects/:projectId/applications/");
export const getOpenProjectApplicationsRecommendedList = list("OPEN_PROJECT_APPLICATIONS_RECOMMENDED_LIST", "/projects/:projectId/applications/");
export const getOpenProjectApplicationsPreselectedRecommendedList = list("OPEN_PROJECT_APPLICATIONS_PRESELECTED_RECOMMENDED_LIST", "/projects/:projectId/applications/");
export const getSubmittedConceptNotesList = list("SUBMITTED_CONCEPT_NOTES_LIST", "/dashboard/submitted-cn/");
export const getPendingOffersList = list("PENDING_OFFERS_LIST", "/dashboard/pending-offers/");
export const sendForDecision = create("SEND_FOR_DECISION_CREATE", "/projects/:id/send-for-decision/");
export const getPseaInitialAssessmentDetails = details("PSEA_INITIAL_ASSESSMENT_DETAILS", "/psea/:partnerId/psea-initial-assessment/");
export const updatePseaInitialAssessment = update("PSEA_INITIAL_ASSESSMENT_UPDATE", "/psea/:partnerId/psea-initial-assessment/");
export const getPseaInitialAssessmentDocumentsList = list("PSEA_INITIAL_ASSESSMENT_DOCUMENTS_LIST", "/psea/:partnerId/psea-initial-assessment-documents/");
export const createPseaInitialAssessmentDocument = create("PSEA_INITIAL_ASSESSMENT_DOCUMENT_CREATE", "/psea/:partnerId/psea-initial-assessment-documents/");
export const deletePseaInitialAssessmentDocument = destroy("PSEA_INITIAL_ASSESSMENT_DOCUMENT_DELETE", "/psea/:partnerId/psea-initial-assessment-documents/:id/");
export const getPseaSelfAssessmentDetails = details("PSEA_SELF_ASSESSMENT_DETAILS", "/psea/:partnerId/psea-self-assessment/");
export const updatePseaSelfAssessmentQuestion = update("PSEA_SELF_ASSESSMENT_QUESTION_UPDATE", "/psea/:partnerId/psea-self-assessment-question/");
export const updatePseaSelfAssessment = update("PSEA_SELF_ASSESSMENT_UPDATE", "/psea/:partnerId/update-self-assessment/");
export const getPseaSelfAssessmentDocumentsList = list("PSEA_SELF_ASSESSMENT_DOCUMENTS_LIST", "/psea/:partnerId/psea-self-assessment-documents/");
export const createPseaSelfAssessmentDocument = create("PSEA_SELF_ASSESSMENT_DOCUMENT_CREATE", "/psea/:partnerId/psea-self-assessment-documents/");
export const deletePseaSelfAssessmentDocument = destroy("PSEA_SELF_ASSESSMENT_DOCUMENT_DELETE", "/psea/:partnerId/psea-self-assessment-documents/:id/");
export const startPseaCsip = details("PSEA_CSIP_START", "/psea/:partnerId/start-psea-csip/");
export const getPseaCsipDetails = details("PSEA_CSIP_DETAILS", "/psea/:partnerId/psea-csip/");
export const createPseaCsipCoreStandardDocument = create("PSEA_CSIP_CORE_STANDARD_DOCUMENT_CREATE", "/psea/:partnerId/psea-csip-core-standard/:coreStandardId/documents/");
export const deletePseaCsipCoreStandardDocument = destroy("PSEA_CSIP_CORE_STANDARD_DOCUMENT_DELETE", "/psea/:partnerId/psea-csip-core-standard/:coreStandardId/documents/:id/");
export const createPseaCsipCoreStandardActivity = create("PSEA_CSIP_CORE_STANDARD_ACTIVITY_CREATE", "/psea/:partnerId/psea-csip-core-standard/:coreStandardId/activities/");
export const updatePseaCsipCoreStandardActivity = update("PSEA_CSIP_CORE_STANDARD_ACTIVITY_UPDATE", "/psea/:partnerId/psea-csip-core-standard/:coreStandardId/activities/:activityId/");
export const extendPseaCsipDeadline = update("PSEA_CSIP_DEADLINE_UPDATE", "/psea/:partnerId/psea-csip-update/");
export const completePseaCsip = update("PSEA_CSIP_COMPLETE_UPDATE", "/psea/:partnerId/psea-csip-update/");
export const getPseaDetails = details("PSEA_DETAILS", "/psea/:partnerId/psea-final-determination/");
export const updatePseaDetails = update("PSEA_UPDATE", "/psea/:partnerId/psea-final-determination/");
export const getPseaCsipActivitiesList = list("PSEA_CSIP_ACTIVITIES_LIST", "/psea/psea-csip-activities/");
export const getPseaSelfAssessmentJustificationsList = list("PSEA_SELF_ASSESSMENT_JUSTIFICATIONS_LIST", "/psea/psea-self-assessment-justifications/");
export const getIpAgreementData = list("IP_AGREEMENT_DATA", "/manage/ip-agreement/");
export const updatePseaCsipCoreStandardComment = update("PSEA_CSIP_CORE_STANDARD_COMMENT", "/psea/:partnerId/psea-csip-core-standard/:coreStandardId/comment/");
export const updatePseaRevisionJustification = update("PSEA_UPDATE_REVISION_JUSTIFICATION", "/psea/:partnerId/psea-revision-update/:revisionId/");
export const createAgencyMember = create("NEW_AGENCY_MEMBER_CREATE", "/agencies/first-access/");
export const createPartnerDeclaration = create("PARTNER_DECLARATION_CREATE", "/partners/partner-declaration/");
export const getPseaRatingCommentsList = list("PSEA_RATING_COMMENTS_LIST", "/psea/:partnerId/psea-rating-comments/");
export const createPseaRatingComment = create("PSEA_RATING_COMMENT_CREATE", "/psea/:partnerId/psea-rating-comments/");
export const getAdminProjectsList = list("ADMIN_PROJECTS_LIST", "/manage/projects/");
export const updateProjectDeadline = update("PROJECT_DEADLINE_UPDATE", "/manage/projects/:id/");
export const completeProjectReviewerAssessments = create("PROJECT_REVIEWER_ASSESSMENT_COMPLETION", "/manage/projects/:id/complete-assessment/:reviewer_id/");
export const adminPartnerCreateUser = create("ADMIN_PARTNER_USER_CREATE", "/manage/:partnerId/partners/");
export const adminPartnerMemberUpdateUser = update("ADMIN_PARTNER_USER_UPDATE", "/manage/partner-member/:id/");
export const adminUpdateUser = update("ADMIN_USER_UPDATE", "/manage/user/:id/admin/");
export const updatePartnerLegalName = update("ADMIN_PARTNER_LEGAL_NAME_UPDATE", "/manage/partner/:partnerId/");
export const adminPartnerDuplicatesList = list("ADMIN_PARTNER_DUPLICATES_LIST", "/manage/partner-duplicates/");
export const getPartnerDuplicatesProfileDataDetails = details("ADMIN_PARTNER_DUPLICATES_PROFILE_DATA_DETAILS", "/manage/partner-duplicates/:id/", false);
export const updatePartnerDuplicatesProfileDataDetails = update("ADMIN_PARTNER_DUPLICATES_PROFILE_DATA_DETAILS_UPDATE", "/manage/partner-duplicates/:id/");
export const createPartnerDuplicates = create("ADMIN_PARTNER_DUPLICATES_CREATE", "/manage/partner-duplicates/");
export const assignPartnerUserAdmin = create("ADMIN_PARTNER_USER_UPDATE", "/manage/partner-member/");
