import React, { useState } from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { reduxForm, Field, change } from "redux-form";
import FieldGroup from "@local/components/field-group";
import Box from "@local/components/box";
import FieldText from "@local/components/field-text";
import FieldSelection from "@local/components/field-selection";
import FieldSelect from "@local/components/field-select";
import FieldDatePicker from "@local/components/field-date-picker";
import Map from "@local/components/map";
import style from "./style.module.scss";
import Button from "@local/components/button";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import FieldFile from "@local/components/field-file";
import FieldRadioGroup from "@local/components/field-radio-group/index";
import { isValidForm, isValidWeightSum } from "../../utils/validators";
import Alert from "@local/components/alert";
import ShowHide from "@local/components/show-hide";
import { PROJECT_TYPES, mapAgenciesIds } from "@local/utils/constants";
import FieldAutosuggest from "@local/components/field-autosuggest";
import { isNumeric, isRequired, isValidDate } from "@local/utils/validators";
import { connect } from "react-redux";
import FormLabel from "@mui/material/FormLabel";
import ProfileConfirmation from "@local/pages/project-details/components/project-submission/components/profile-confirmation";
import { adaptPartnersWithExtendedLabel } from "@local/utils/adapters";

const ProjectModal = ({
  type,
  isOpen,
  onClose,
  countries,
  formValues,
  focalPoints,
  sectors,
  isPopulationFieldVisible,
  concernTypes,
  selectionCriteria,
  selectedLocations,
  attachments,
  criteria,
  onSubmit,
  onClickMap,
  onClickDeleteLocation,
  onChangeSector,
  filteredAreas,
  onClickRemoveAttachment,
  onClickAddAttachment,
  onClickRemoveCriteria,
  onClickAddCriteria,
  title,
  informationText,
  fetchPartnersFunction,
  justifications,
  isInRestrictedMode,
  isDeadlineDatePassed,
  clearDeadlineDate,
  clearNotificationResultsDate,
  clearStartDate,
  clearEndDate,
  agencies,
  currencies,
}) => {
  const [isProfileConfirmed, setIsProfileConfirmed] = useState(false);

  const onChangeExtraClarificationRequestDeadlineDate = () => {
    clearDeadlineDate();
    clearNotificationResultsDate();
    clearStartDate();
    clearEndDate();
  };

  const onChangeExtraClearDeadlineDate = () => {
    clearNotificationResultsDate();
    clearStartDate();
    clearEndDate();
  };

  const onChangeExtraClearNotificationResultsDate = () => {
    clearStartDate();
    clearEndDate();
  };

  const onChangeExtraClearStartDate = () => {
    clearEndDate();
  };

  const onChangeIsProfileConfirmed = () => {
    const updatedValue = !isProfileConfirmed;
    setIsProfileConfirmed(updatedValue);
  };

  const adaptPartnersFunction = (partners) => adaptPartnersWithExtendedLabel(partners, countries);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      footer={
        <>
          <div>
            <Button
              onClick={onClose}
              variant="text"
              text={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={!isValidForm(type, formValues, selectedLocations, attachments, criteria, isInRestrictedMode, isDeadlineDatePassed) || (type === PROJECT_TYPES.UNSOLICITED ? !isProfileConfirmed : false)}
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.button",
              })}
            />
          </div>
        </>
      }
    >
      {informationText ? <Alert type="info" content={informationText} withBottomMargin /> : undefined}
      <span className={style.sectionTitle}>
        {formatMessage({
          id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.title",
        })}
      </span>
      {!isInRestrictedMode ? (
        <>
          <FieldGroup>
            <ShowHide isVisible={type === PROJECT_TYPES.UNSOLICITED}>
              <Field
                component={FieldSelect}
                validate={[isRequired]}
                id="agency"
                name="agency"
                label={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.label",
                })}
                placeholder={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.label",
                })}
                valueField="id"
                labelField="name"
                options={agencies}
              />
            </ShowHide>
            <Field
              component={FieldText}
              validate={[isRequired]}
              id="title"
              name="title"
              label={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelOne",
              })}
              placeholder={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.placeholder",
              })}
              type="text"
              withCharactersCount
              maxLength={225}
            />
          </FieldGroup>
        </>
      ) : undefined}
      {!isInRestrictedMode ? (
        <>
          <Box
            title={formatMessage({
              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.projectLocation",
            })}
            content={
              <>
                <div className={style.locationWrapper}>
                  <div className={style.newLocationSearchWrapper}>
                    <Field
                      component={FieldSelection}
                      validate={[isRequired]}
                      id="countries"
                      name="countries"
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.country",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.country",
                      })}
                      valueField="value"
                      labelField="label"
                      options={countries}
                      multiple
                      height="400px"
                      width="300px"
                    />
                  </div>
                  {formValues?.countries?.length ? (
                    <div className={style.newLocationMapWrapper}>
                      <Map height="400px" onClickMap={onClickMap} markers={selectedLocations} onClickDeleteMarker={onClickDeleteLocation} countryCodes={formValues?.countries ?? []} isError={selectedLocations?.length === 0} />
                    </div>
                  ) : (
                    <span className={style.newLocationMissingCountry}>
                      {formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.noCountrySelected",
                      })}
                    </span>
                  )}
                </div>
              </>
            }
          />
        </>
      ) : undefined}
      <ShowHide isVisible={type !== PROJECT_TYPES.UNSOLICITED}>
        <FieldGroup>
          <Field
            component={FieldSelect}
            validate={[isRequired]}
            id="focal_points"
            name="focal_points"
            label={formatMessage({
              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.projectProgrammeFocalPoints",
            })}
            placeholder={formatMessage({
              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectTheNameOfTheFocalPoint",
            })}
            valueField="id"
            labelField="label"
            options={focalPoints}
            multiple
          />
        </FieldGroup>
      </ShowHide>
      {!isInRestrictedMode ? (
        <>
          <Box
            title={formatMessage({
              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelTwo",
            })}
            content={
              <>
                <FieldGroup>
                  <Field
                    component={FieldSelect}
                    validate={[isRequired]}
                    id="sectors"
                    name="sectors"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.sector",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.sector",
                    })}
                    valueField="id"
                    labelField="name"
                    options={sectors}
                    onChangeExtra={onChangeSector}
                    multiple
                  />
                  <Field
                    component={FieldSelect}
                    validate={[isRequired]}
                    id="areas"
                    name="areas"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.areaOfSpecialization",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.areaOfSpecialization",
                    })}
                    valueField="id"
                    labelField="name"
                    options={filteredAreas}
                    multiple
                  />
                </FieldGroup>
              </>
            }
          />
        </>
      ) : undefined}
      <ShowHide isVisible={type !== PROJECT_TYPES.UNSOLICITED}>
        {!isInRestrictedMode ? (
          <>
            <FieldGroup>
              {isPopulationFieldVisible ? (
                <Field
                  component={FieldSelect}
                  id="population"
                  name="population"
                  label={formatMessage({
                    id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelThree",
                  })}
                  placeholder={formatMessage({
                    id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelThree",
                  })}
                  valueField="value"
                  labelField="label"
                  options={concernTypes}
                  multiple
                />
              ) : undefined}
              <Field
                component={FieldText}
                validate={[isRequired]}
                id="description"
                name="description"
                label={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.projectBackground",
                })}
                placeholder={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.projectBackground",
                })}
                type="text"
                withCharactersCount
                maxLength={5000}
                multiline
              />
              <Field
                component={FieldText}
                validate={[isRequired]}
                id="goal"
                name="goal"
                label={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.expectedResults",
                })}
                placeholder={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.expectedResults",
                })}
                type="text"
                withCharactersCount
                maxLength={5000}
                multiline
              />
              <Grid container spacing={2}>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Field
                    component={FieldText}
                    validate={[isNumeric]}
                    id="indicative_budget"
                    name="indicative_budget"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.indicativeBudgetOptional",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.provideIndicativeBudget",
                    })}
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Field
                    component={FieldSelect}
                    id="indicative_budget_currency"
                    name="indicative_budget_currency"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectTheIndicativeBudgetCurrency",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectTheIndicativeBudgetCurrency",
                    })}
                    valueField="code"
                    labelField="label"
                    options={currencies}
                  />
                </Grid>
              </Grid>
              <Field
                component={FieldText}
                id="other_information"
                name="other_information"
                label={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.otherInformationOptional",
                })}
                placeholder={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.otherInformationOptional",
                })}
                type="text"
                withCharactersCount
                maxLength={5000}
                multiline
              />
            </FieldGroup>
          </>
        ) : undefined}
        {!isDeadlineDatePassed ? (
          <>
            <Box
              title={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.deadlinesTitle",
              })}
              content={
                <>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <ShowHide isVisible={type === PROJECT_TYPES.OPEN}>
                            <Field
                              component={FieldDatePicker}
                              validate={[isRequired, isValidDate]}
                              minimumDate={new Date()}
                              onChangeExtra={onChangeExtraClarificationRequestDeadlineDate}
                              id="clarification_request_deadline_date"
                              name="clarification_request_deadline_date"
                              label={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.requestForClarificationDeadline",
                              })}
                              placeholder={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.pickADateSixth",
                              })}
                            />
                          </ShowHide>
                        </Grid>
                        <Grid item xs={4}>
                          <ShowHide isVisible={type === PROJECT_TYPES.OPEN}>
                            <Field
                              component={FieldDatePicker}
                              validate={[isRequired, isValidDate]}
                              minimumDate={formValues["clarification_request_deadline_date"]}
                              onChangeExtra={onChangeExtraClearDeadlineDate}
                              id="deadline_date"
                              name="deadline_date"
                              label={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.applicationDeadline",
                              })}
                              placeholder={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.pickADateThird",
                              })}
                            />
                          </ShowHide>
                        </Grid>
                        <Grid item xs={4}>
                          <ShowHide isVisible={type === PROJECT_TYPES.OPEN}>
                            <Field
                              component={FieldDatePicker}
                              validate={[isRequired, isValidDate]}
                              minimumDate={formValues["deadline_date"]}
                              onChangeExtra={onChangeExtraClearNotificationResultsDate}
                              id="notif_results_date"
                              name="notif_results_date"
                              label={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.notificationOfResults",
                              })}
                              placeholder={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.pickADateFourth",
                              })}
                            />
                          </ShowHide>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Field
                            component={FieldDatePicker}
                            validate={[isRequired, isValidDate]}
                            minimumDate={formValues["notif_results_date"]}
                            onChangeExtra={onChangeExtraClearStartDate}
                            id="start_date"
                            name="start_date"
                            label={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.estimatedStartDate",
                            })}
                            placeholder={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.pickADateFirst",
                            })}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            component={FieldDatePicker}
                            validate={[isRequired, isValidDate]}
                            minimumDate={formValues["start_date"]}
                            id="end_date"
                            name="end_date"
                            label={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.estimatedEndDate",
                            })}
                            placeholder={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.pickADateSecond",
                            })}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </>
        ) : undefined}
      </ShowHide>
      <ShowHide isVisible={type === PROJECT_TYPES.OPEN && !isInRestrictedMode}>
        <Box
          title={formatMessage({
            id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.cnTemplate",
          })}
          content={
            <div className={style.additionalItemWrapper}>
              <div>
                <FieldGroup>
                  <Field
                    component={FieldRadioGroup}
                    validate={[isRequired]}
                    id="is_cn_substitute"
                    name="is_cn_substitute"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.cnTemplate",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.cnTemplate",
                    })}
                    fullWidthLabel
                    options={[
                      {
                        value: false,
                        label: formatMessage({
                          id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.attachmentsStandard",
                        }),
                      },
                      {
                        value: true,
                        label: formatMessage({
                          id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.attachmentsNonStandard",
                        }),
                      },
                    ]}
                  />
                  {formValues?.is_cn_substitute?.toString() === "true" ? (
                    <Field
                      component={FieldFile}
                      validate={[isRequired]}
                      id={"cn_template"}
                      name={"cn_template"}
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.buttonOne",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.buttonOne",
                      })}
                    />
                  ) : (
                    <></>
                  )}
                </FieldGroup>
              </div>
            </div>
          }
        />
      </ShowHide>
      <ShowHide isVisible={type !== PROJECT_TYPES.UNSOLICITED && type !== PROJECT_TYPES.DIRECT}>
        <Box
          title={formatMessage({
            id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.attachmentsOptional",
          })}
          sections={attachments.map((item, index) => (
            <React.Fragment key={`attachment_${item.uniqueId}`}>
              <div className={style.additionalItemWrapper}>
                <div>
                  <FieldGroup>
                    <Field
                      component={FieldFile}
                      id={`attachment_${item.uniqueId}`}
                      name={`attachment_${item.uniqueId}`}
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.buttonOne",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.buttonOne",
                      })}
                    />
                    <Field
                      component={FieldText}
                      validate={formValues[`attachment_${item.uniqueId}`] ? [isRequired] : undefined}
                      id={`attachment_description_${item.uniqueId}`}
                      name={`attachment_description_${item.uniqueId}`}
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.attachmentDescription",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.attachmentDescription",
                      })}
                      type="text"
                    />
                  </FieldGroup>
                </div>
                {index !== 0 ? (
                  <div>
                    <IconButton onClick={() => onClickRemoveAttachment(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </React.Fragment>
          ))}
          actions={
            <>
              <Button
                text={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.addNew",
                })}
                startIcon={<AddIcon />}
                onClick={onClickAddAttachment}
                variant="text"
              />
            </>
          }
        />
        {!isInRestrictedMode ? (
          <>
            <ShowHide isVisible={type === PROJECT_TYPES.OPEN}>
              <Box
                title={formatMessage({
                  id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectionCriteria",
                })}
                error={
                  !isValidWeightSum(criteria, formValues)
                    ? formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.criteriaWeightSumError",
                      })
                    : undefined
                }
                content={
                  <>
                    <FieldGroup>
                      <Field
                        component={FieldRadioGroup}
                        validate={[isRequired]}
                        id="has_weighting"
                        name="has_weighting"
                        label={formatMessage({
                          id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.isWeightingRelevantForThisProject",
                        })}
                        placeholder={formatMessage({
                          id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.isWeightingRelevantForThisProject",
                        })}
                        options={[
                          {
                            value: true,
                            label: formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.yes",
                            }),
                          },
                          {
                            value: false,
                            label: formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.no",
                            }),
                          },
                        ]}
                      />
                    </FieldGroup>
                  </>
                }
                sections={criteria.map((item, index) => (
                  <React.Fragment key={`criteria_${item.uniqueId}`}>
                    <div className={style.additionalItemWrapper}>
                      <div>
                        <FieldGroup>
                          <Field
                            component={FieldSelect}
                            validate={[isRequired]}
                            id={`selection_criteria_${item.uniqueId}`}
                            name={`selection_criteria_${item.uniqueId}`}
                            label={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelFour",
                            })}
                            placeholder={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelFour",
                            })}
                            valueField="value"
                            labelField="label"
                            options={selectionCriteria.filter(
                              (option) =>
                                option.value === formValues[`selection_criteria_${item.uniqueId}`] ||
                                !Object.keys(formValues)
                                  .filter((key) => key.startsWith("selection_criteria_"))
                                  .map((key) => formValues[key])
                                  .includes(option.value) ||
                                option.value === "Oth",
                            )}
                          />
                          <Field
                            component={FieldText}
                            validate={formValues[`selection_criteria_${item.uniqueId}`] === "Oth" ? [isRequired] : undefined}
                            id={`selection_criteria_description_${item.uniqueId}`}
                            name={`selection_criteria_description_${item.uniqueId}`}
                            label={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.criteriaDescription",
                            })}
                            placeholder={formatMessage({
                              id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.criteriaDescription",
                            })}
                            type="text"
                          />
                          {formValues?.has_weighting?.toString() === "true" ? (
                            <Field
                              component={FieldText}
                              validate={[isRequired]}
                              id={`selection_criteria_weight_${item.uniqueId}`}
                              name={`selection_criteria_weight_${item.uniqueId}`}
                              label={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.criteriaWeight",
                              })}
                              placeholder={formatMessage({
                                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.criteriaWeight",
                              })}
                              type="number"
                            />
                          ) : undefined}
                        </FieldGroup>
                      </div>
                      {index !== 0 ? (
                        <div>
                          <IconButton onClick={() => onClickRemoveCriteria(item)}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </React.Fragment>
                ))}
                actions={
                  <>
                    <Button
                      text={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.addNew",
                      })}
                      startIcon={<AddIcon />}
                      onClick={onClickAddCriteria}
                      variant="text"
                    />
                  </>
                }
              />
            </ShowHide>
          </>
        ) : undefined}
      </ShowHide>
      {!isInRestrictedMode ? (
        <>
          <ShowHide isVisible={type === PROJECT_TYPES.DIRECT}>
            <Box
              title={formatMessage({
                id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectPartners",
              })}
              content={
                <>
                  <FieldGroup>
                    <Field
                      component={FieldAutosuggest}
                      validate={[isRequired]}
                      id="partner"
                      name="partner"
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.selectPartnersOne",
                      })}
                      fetchFunction={fetchPartnersFunction}
                      adapterFunction={adaptPartnersFunction}
                      valueField="id"
                      labelField="legal_name"
                    />
                    <Field
                      component={FieldSelect}
                      validate={[isRequired]}
                      id="ds_justification_select"
                      name="ds_justification_select"
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelFive",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelFive",
                      })}
                      valueField="value"
                      labelField="label"
                      options={justifications}
                      multiple
                    />
                    <Field
                      component={FieldText}
                      validate={[isRequired]}
                      id="justification_reason"
                      name="justification_reason"
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelSix",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.labelSix",
                      })}
                      type="text"
                      withCharactersCount
                      maxLength={5000}
                      multiline
                    />
                    <Field
                      component={FieldFile}
                      id="ds_attachment"
                      name="ds_attachment"
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.buttonOne",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.buttonOne",
                      })}
                    />
                    <Field
                      component={FieldRadioGroup}
                      validate={[isRequired]}
                      id="is_cn_required"
                      name="is_cn_required"
                      label={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.confirmQuestion",
                      })}
                      placeholder={formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.confirmQuestion",
                      })}
                      options={[
                        {
                          value: true,
                          label: formatMessage({
                            id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.allow",
                          }),
                        },
                        {
                          value: false,
                          label: formatMessage({
                            id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.deny",
                          }),
                        },
                      ]}
                    />
                  </FieldGroup>
                </>
              }
            />
          </ShowHide>
        </>
      ) : undefined}
      <ShowHide isVisible={type === PROJECT_TYPES.UNSOLICITED}>
        <Box
          title={formatMessage({
            id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.cn",
          })}
          content={
            <div className={style.additionalItemWrapper}>
              <div>
                <FieldGroup>
                  <Field
                    component={FieldFile}
                    validate={[isRequired]}
                    id="cn"
                    name="cn"
                    label={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.uploadFile",
                    })}
                    placeholder={formatMessage({
                      id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.uploadFile",
                    })}
                  />
                  <div className={style.boxSection}>
                    <FormLabel component="legend">
                      {formatMessage({
                        id: "pages.projects.list.cmp.manage.project.modal.cmp.project.modal.idx.cnLabel",
                      })}
                    </FormLabel>
                  </div>
                </FieldGroup>
              </div>
            </div>
          }
        />
        <ProfileConfirmation checked={isProfileConfirmed} onChange={onChangeIsProfileConfirmed} />
      </ShowHide>
    </Modal>
  );
};

ProjectModal.propTypes = {};

const mapStateToProps = (state) => ({
  agencies: mapAgenciesIds(state?.agenciesList?.data?.data?.results) ?? [],
});

const mapDispatchToProps = (dispatch) => ({
  clearDeadlineDate: () => dispatch(change("projectModalForm", "deadline_date", null)),
  clearNotificationResultsDate: () => dispatch(change("projectModalForm", "notif_results_date", null)),
  clearStartDate: () => dispatch(change("projectModalForm", "start_date", null)),
  clearEndDate: () => dispatch(change("projectModalForm", "end_date", null)),
});

export default reduxForm({
  form: "projectModalForm",
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(ProjectModal));
